/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

.disabled.field {
    label {
        opacity: .9 !important;
    }
    .input {
        opacity: .9 !important;
    }
}
.error.field {
    .input {
        input {
            background-color: #FFF4F5;
            border: 1px #DBABAB solid;
        }
    }
}
