/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

.dropdown {
    &.error {
        .default.text {
            color: #CEC9CA !important;
        }
    }
}
.field.error {
    .dropdown:not(.icon) {
        background-color: #FFF4F5;
        border: 1px #DBABAB solid;
    }
}
