/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

.field {
    .google-autocomplete {
        width: 100% !important;

    }
    &.error {
        .google-autocomplete {
            background-color: #FFF4F5;
            border: 1px #DBABAB solid;
        }
    }
}
