/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

.uploader {
    width: 100%;
    height: 250px;
    background-color: #f3f3f3;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #e2e2e2;
    transition: transform 200ms;
    &-active {
        background-color: #9acef2;
        border: 1px dashed #6ea2c6;
    }
    &-rejected {
        background-color: #FFF4F5;
        border: 1px dashed #DBABAB;
    }
    p {
        padding: 0 15px;
        text-align: center;
    }
    img {
        border-radius: 5px;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    .clear-action {
        background-color: rgba(0,0,0,0.4);
        border: 0;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        color: #e9e9e9;
        z-index: 10;
    }
    .react-pdf__Page__canvas,
    .react-pdf__Page,
    .react-pdf__Document{
        width: 100% !important;
        height: 100% !important;
    }
}
