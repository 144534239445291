/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

.navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background: #000001;  /* fallback for old browsers */
    border: none;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    height: 75px;
    margin-right: 0;
    margin-left: 0;
    z-index: 99;
}
