/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// MEDIA QUERY MANAGER
/*
    0 - 600px:  Phone
    600 - 900px: tablet portrait
    900 - 1200px: tablet landscape
    [1200 - 1800px]: is where our normal style apply
    188px+:     big desktops

    $breakpoint args options
    - phone
    - tab-port
    - tab-land
    - big-desktop
*/

// ORDER: Base + Typography > general layout + grid > page layout > components

// 1em = 6px
@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media(max-width: 37.5em) { // 600px
            @content
        }
    }
    @if $breakpoint == tab-port {
        @media(max-width: 56.25em) { // 900px
            @content
        }
    }
    @if $breakpoint == tab-land {
        @media(max-width: 75em) { // 1200px
            @content
        }
    }
    @if $breakpoint == big-desktop {
        @media(min-width: 112.5em) { // 1800px
            @content
        }
    }
}
