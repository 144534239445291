/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

.tree-selector {
    width: 100%;
    outline: none;
    &.error {
        .rc-tree-select-selection {
            border-radius: 4px;
            padding: 5px 0;
            outline: none;
            border: 1px #DBABAB solid;
            background-color: #FFF4F5;
            &:hover {
                border: 1px #DBABAB solid;
                background-color: #FFF4F5;
            }
            &:focus {
                border: 1px #DBABAB solid;
                background-color: #FFF4F5;
            }
        }
    }
    .rc-tree-select-search__field__placeholder {
        margin-top: 5px;
        margin-left: 8px;
        color: #c9c9c9;
    }
    &.rc-tree-select-enabled.rc-tree-select-focused {
        .rc-tree-select-selection {
            border: 1px rgba(34,36,38,.35) solid;
            box-shadow: none;
        }
    }
    .rc-tree-select-selection--single {
        min-height: 38px;
        .rc-tree-select-arrow {
            margin-top: 5px;
            margin-right: 5px;
        }
        .rc-tree-select-selection__placeholder {
            margin-top: 5px;
            margin-left: 4px;
            color: #c9c9c9;
        }
    }
    .rc-tree-select-selection {
        border-radius: 4px;
        padding: 5px 0;
        outline: none;
        border: 1px #e2e2e2 solid;
        &:hover {
            border: 1px rgba(34,36,38,.35) solid;
            box-shadow: none;
        }
        &:focus {
            border: 1px rgba(34,36,38,.35) solid;
            box-shadow: none;
        }
    }
}
.rc-tree-select-tree-node-selected {
    background-color: #aacffa !important;
    border: 1px #10a9eb solid !important;
    border-radius: 4px;
    padding: 3px;
}
.rc-tree-select-tree-node-content-wrapper.rc-tree-select-tree-node-content-wrapper-normal,
.rc-tree-select-tree-node-content-wrapper.rc-tree-select-tree-node-content-wrapper-open,
.rc-tree-select-tree-node-content-wrapper.rc-tree-select-tree-node-content-wrapper-close {
    cursor: pointer;
}
.rc-tree-select-arrow:after {
    border-color: #4D4D4D transparent transparent transparent;
    border-width: 4px 4px 0 4px;
    margin-left: -6px;
}
