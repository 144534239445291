/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

.react-autosuggest__container {
    position: relative;
    display: inline-block;
}

.react-autosuggest__input {
    width: 240px;
    height: 38px;
    padding: 10px 20px;
    //font-family: Helvetica, sans-serif;
    //font-weight: 300;
    //font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input:focus {
    outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: 30px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 999999;
    overflow-y: scroll;
    overflow-x: hidden;
    //max-height: 200px;
    height: 200px;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 5px 5px;
}

.react-autosuggest__suggestion:not(:first-child) {
    border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #0C7EAF;
    color: #fff;
}

.suggestion-content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
}

.name {
    margin-left: 68px;
    line-height: 45px;
}

.highlight {
    color: #ee0000;
    font-weight: bold;
}

.react-autosuggest__suggestion--focused .highlight {
    color: #120000;
}
