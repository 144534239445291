/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

.create-place-modal {
    .coming-soon-checkbox {
      margin: 15px;
    }

    &--content {
        height: 65vh;

        .map {
            position: relative;
            width: 95% !important;
        }

        .autocomplete-container {
            position: absolute;
            top: 100%;
            background-color: white;
            border: 1px solid #555555;
            width: 100%;
            z-index: 55000;
        }

        .main-section {
            margin-top: 15px;
            height: 55vh;
            overflow-y: auto;
        }
    }

    .ui.toggle.checkbox input {
        &:focus:checked ~ {
          .box:before, label:before {
            background-color: #5fb257 !important;
          }
        }

        &:checked ~ {
          .box:before, label:before {
            background-color: #5fb257 !important;
          }
        }
      }
}
