/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

.photo-grid {
    border-top: 1px #e2e2e2 solid;
    &.error {
        .photo-grid-list {
            background-color: #FFF4F5;
            border: 1px #DBABAB solid;
        }
    }
    &-add-action {
    }
    &-list {
        position: relative;
        background-color: #F1F1F1;
        padding: 5px;
        border-radius: 5px;
        margin-top: 15px;
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        min-height: 180px;
        &-empty {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        &-item {
            position: relative;
            border: 1px #555 dashed;
            border-radius: 5px;
            height: 160px;
            width: 19.6%;
            margin: 5px 2px;
            overflow: hidden;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            &:hover {
                .photo-grid-list-item-action-container {
                    height: 30px;
                    opacity: 1;
                }
            }
            &-action-container {
                z-index: 5;
                transition: height 200ms, opacity 200ms;
                position: absolute;
                opacity: 0;
                height: 0;
                background-color: rgba(0,0,0,0.5);
                left: 0;
                right: 0;
                bottom: 0;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                display: flex;
                flex-direction: row;
                > button {
                    display: flex;
                    flex: 1 1 auto;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    &:first-of-type {
                        border-right: 1px #fafafa solid;
                    }
                    .icon {
                        margin-top: -5px;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
