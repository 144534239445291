/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

// Abstracts
@import "abstracts/mixins";

// Base
@import "base/base";

// Components
@import "components/navbar";
@import "components/sidebar";
@import "components/create_event_modal";
@import "components/create_place_modal";
@import "components/create_user_modal";
@import "components/uploader";
@import "components/media_row";
@import "components/datepicker";
@import "components/users_autocomplete";
@import "components/google_autocomplete";
@import "components/dropdown";
@import "components/photo_grid";
@import "components/input";
@import "components/search_dropdown";
@import "components/tree_selector";

// Vendor
@import "vendor/autosuggest";
@import "vendor/datepicker";
@import "vendor/editor";
@import "vendor/tree";

.ui.modal>.close {
  top: 12px;
  right: 10px;
  color: #999;
}
