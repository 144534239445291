/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

$search-bg: rgba(0,0,0,0.85);

$search-input-width: 100%;
$search-input-bg: #fff;
$search-input-color: #000;
$search-input-height: 50px;

$search-list-width: 100%;
$search-list-top: $search-input-height - 13;
$search-list-bg: #fff;
$search-list-color: transparentize(#000, 0.5);
$search-list-color-highlight: #000;

.users-autocomplete {
    position: relative;

    &.error {
        .react-autosuggest__input {
            background-color: #FFF4F5;
            border: 1px #DBABAB solid !important;
        }
    }
    .react-autosuggest__container {
        width: 100%;
        position: relative;
        .react-autosuggest__input {
            border: 1px #DEDEDE solid;
            padding: 10px 15px 10px 40px;
            width: $search-input-width;
            -webkit-appearance: none;
            &::placeholder { color: #cbcbcb; }
        }
        .react-autosuggest__suggestions-container {
            display: none;
        }
        .react-autosuggest__suggestions-container--open {
            display: block;
            position: absolute;
            background-color: #fcfcfc;
            border-color: #DEDEDE;
            top: $search-list-top;
            width: $search-list-width;
            text-align: left;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            z-index: 5;
            overflow: hidden;
        }
        .react-autosuggest__suggestions-list {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }
        .react-autosuggest__suggestion {
            cursor: pointer;
            padding: 5px 20px;
            font-size: 14px;
            > div {
                display: flex;
                align-items: center;

                .user-avatar {
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .user-category {
                    font-size: 10px;
                    color: #666;
                    margin-left: 10px;
                    align-self: flex-end;
                    margin-bottom: 7px;
                }

                span {
                    white-space: pre;

                    &:last-of-type {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
            .highlight {
                color: #111;
            }
        }
        .react-autosuggest__suggestion--highlighted {
            background-color: rgba(0,0,0,0.1);
            color: #555;
        }
    }
    .user-category-preview {
        font-size: 10px;
        color: #e2e2e2;
        position: absolute;
        right: 45px;
        top: 50%;
        transform: translate(0, -50%);
        background-color: #666;
        border-radius: 50px;
        padding: 5px 10px;
    }
    .user-photo {
        height: 25px;
        width: 25px;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translate(0, -50%);
        border-radius: 50%;
        z-index: 1;
    }
    .clear-button {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translate(0, -50%);
    }
}
