/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

.media-row {
    border-top: 1px #e2e2e2 solid;
    &.borderless {
        border-top: 0 !important;
    }
    &-action-container {
        display: flex !important;
        justify-content: space-between;
        padding: 0 20px;
        flex-direction: row;
        &-title {
            margin-bottom: 15px !important;
        }
        &-action {
            margin-left: 20px !important;
        }
    }
    &-user-info-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 20px 0;
        > h2 {
            margin: 0 10px 0 0;
        }
        > img {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            margin-right: 10px;
        }
        > span {
            font-size: 12px;
            color: #666;
            align-self: flex-end;
            margin-bottom: 3px;
        }
    }
    &-image-container {
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f3f3f3;
        border-radius: 5px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border: 1px transparent solid;
            border-radius: 5px;
            z-index: 1;
        }
        &-hint {
            position: absolute;
            z-index: 0;
        }
    }
    .url {
        margin-top: 20px;
        margin-bottom: 0;
        font-size: 95%;
        color: #828282;
    }
    .date {
        margin: 0;
        font-size: 80%;
        color: #828282;
    }
}
