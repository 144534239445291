/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

*, *::after, *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    // This defines what a 1rem is
    //font-size: 62.5%; // 1rem = 10px; 10px/16px = 62.5%

    // Attention - Order is important
    @include respond(tab-land) { // 1rem = 9px; 9/16 = 56.25%
        font-size: 56.25%;
    }

    @include respond(tab-port) {
        font-size: 50%; // 1rem = 8px; 8/16 = 50%
    }

    @include respond(big-desktop) {
        font-size: 87.5%; // 1rem = 12px; 14/16 = 85%
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: #fbfbfb url("../../img/background_pattern.png");

    #root {
        display: flex;
        height: 100%;
    }

    // move this to a more proper place
    #pagination-grid {
        margin-left: 0;
        margin-right: 0;
    }

    @include respond(tab-port) {
        padding: 0;
    }
}

.rc-time-picker-panel-select {
    overflow-x: hidden;
}
