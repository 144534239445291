/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

.search-dropdown {
    display: flex;
    flex-direction: row;
}
