/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

.create-event-modal {

    &--content {
        height: 75vh;

        .main-section {
            margin-top: 15px;
            height: 65vh;
            overflow-y: auto;
        }
    }
}
