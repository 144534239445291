/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

.create-user-modal {
    .rc-tree-select-search__field {
        border: none !important;
        font-size: 100% !important;
        background: transparent !important;
        outline: 0 !important;
        width: 100% !important;
        padding: 0 !important;
    }

    &__social-media__input {
        margin-bottom: 20px;
    }
}
