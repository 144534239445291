/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

.form-date-input .input > div {
    width: 100%;
}

.form-date-input .input .react-datepicker-wrapper {
    width: 100%;
}

.form-date-input .input .react-datepicker__input-container {
    width: 100%;
}

.date-picker {
    width: 100% !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    padding-left: 0;
    padding-right: 0;
    width: 80px;
}
