/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

.date-picker-container {
    .ui.fluid.input {
        z-index: 2;
        > div:first-of-type {
            width: 100% !important;
            .react-datepicker-wrapper {
                width: 100% !important;
                .react-datepicker__input-container {
                    width: 100%;
                    .date-picker {
                        &.error {
                            background-color: #FFF4F5;
                            border: 1px #DBABAB solid;
                        }
                    }
                }
            }
        }
    }
}
