/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 100;
    padding-left: 0 !important;
    padding-right: 0 !important;

    &--logo-box {
        background: #000001;
        height: 75px;
    }

    &--menu-box {
        background: #000001;  /* fallback for old browsers */
        height: calc(100% - 75px);
        padding-top: 30px;
        overflow-y: auto;
        overflow-x: hidden;

        div.active {
            a, i {
                color: #ffc300 !important;
            }
        }
    }

    &--footer {
        //background-color: #333;
        height: 75px;
        margin-top: -75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
            color: white;
            font-size: 12px;
            padding-bottom: 5px;
        }
    }
}
