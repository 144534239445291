/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

.editor-container {
    border: 1px #DEDEDE solid;
    border-radius: 5px;
    padding: 1em;
    &.error {
        border: 1px #DBABAB solid;
        background-color: #FFF4F5;
    }
}
.actions-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    .action {
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        min-width: 30px;
        min-height: 30px;
        border-radius: 5px;
        padding: 5px;
        margin-right: 5px;
        border-width: 0;
        background-color: #DEDEDE;
    }
}
